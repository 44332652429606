import React from "react";

const Footer = () => {
  return (
    <div className="bg-black md:px-20 mt-10 text-white text-center p-5">
      <div>
        <h1 className="text-xl">
          <span className="text-2xl font-bold text-red-500"> 18 + disclaimer:</span> This website is only for 18+ users. if
          you are from Telangana, Orissa, Assam, Sikkim and Nagaland please
          leave the website immediately Be aware of fraudsers, we only deal via
          whatsapp. we only promotes Fantasy Sports,
          <br />
          <span className="text-xl text-red-700">
            "No real money involvemnet in this game"
          </span>
        </h1>
      </div>
      <p>Copyright &copy; 2023 DreamArc</p>
    </div>
  );
};

export default Footer;
