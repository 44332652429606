import React from "react";

const Hero = () => {
  return (
    <div
      className="bg-no-repeat bg-cover bg-center relative  h-[70vh] md:h-[70vh] w-screen"
      style={{
        backgroundImage: `url('https://png.pngtree.com/background/20231101/original/pngtree-abstract-cricket-background-picture-image_5839694.jpg')`,
      }}
    >
      <div className="absolute inset-0 bg-black opacity-35 z-35 w-full h-[70vh]"></div>
      <div className=" absolute flex justify-center items-center w-full h-[70vh] z-40">
        <div className="flex flex-col items-center justify-center space-y-7">
          <h1 className="md:text-7xl text-5xl  text-center font-[monstrat] text-white uppercase">
          Welcome to DreamArc
          </h1>
          <p className=" text-white text-center md:text-2xl text-xl uppercase">
            We offers Best & genuine experience try your best with us!
          </p>
          <button className="bg-[#DA1300] uppercase font-bold animate-bounce  hover:bg-red-900 border-2 text-xl text-white md:px-32  px-20 py-3 rounded-md">
            Grab your !d now
          </button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
