import React, { useEffect } from "react";
import Hero from "./HEro";
import Footer from "./Footer";
import ReactGA from "react-ga4";

const Homepage = () => {
  useEffect(() => {
    ReactGA.initialize("G-SHPEYX0F7H");
    ReactGA.send("pageview");
  }, []);

  const handleonclick = () => {
    ReactGA.event({
      category: "CTA",
      action: "Claim Now Button Clicked",
      label: "Claim Bonus",
    });
    window.open("https://bit.ly/Indrawelcome", "_blank");
  };

  return (
    <div className="w-full h-screen" onClick={handleonclick}>
      <div>
        <Hero />
      </div>
      <div className="grid md:p-10 md:grid-cols-2 grid-cols-1 gap-10 px-5 mt-10 md:px-32 text-black ">
        <div className="md:space-y-10 space-y-4 md:mt-10">
          <h1 className="md:text-5xl text-3xl  font-[monstrat]  uppercase">
            About DreamArc
          </h1>
          <p className="md:text-2xl ">
            Welcome to DreamArc, we are responsible ID promters, we deaels in
            all kindds of sports like online cricket id and sevreal types of
            gamming sports ids play games with our sports ids we helped 98+
            players to find best online games.
          </p>
          <button className="bg-[#DA1300] uppercase font-bold animate-bounce  hover:bg-red-900 border-2 text-xl text-white md:px-32 px-20   py-3 rounded-md">
            Grab your !d now
          </button>
        </div>
        <div className="md:p-5 rounded-xl w-full md:h-[50vh] overflow-hidden">
          <img
            className=" w-full h-full rounded-xl object-cover"
            src="https://png.pngtree.com/background/20231101/original/pngtree-abstract-cricket-background-picture-image_5839694.jpg"
            alt=""
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Homepage;
